import React from 'react';

/*Styles*/
import {
    Container,
    ContainerRow,
    Observation,
    Row1,
    Row2,
    Pagination,
    Row3,
    SendButton,
    FirstColumn,
    SecondColumn,
    Unavailable,
    Filled,
    Availability,
    PaginationGrid,
    ContainerModal,
    Row1Modal,
    Row2Modal,
    Row3Modal,
    Row4Modal,
    PositiveButton,
    NegativeButton,
    Row2Closed,
} from './send-nps-tab.styles';

/* Components */
import PopUp from '../../../../components/popup/popup.component';

/* api */
import { findAllOlderUserAlignments } from '../../../../api/ApiAlignment';
import {
    createNpsAnswers,
    findAllAlignmentsPaged,
    findNpsStatus,
} from '../../../../api/ApiNps';
import { getAllDepartments } from '../../../../api/ApiDepartment';
import {
    getAllOlderUsersInCompany,
    getAllOlderUsersInDepartment,
} from '../../../../api/ApiUser';

/* context */
import { authContext } from '../../../../contexts/AuthContext';

/* Material UI */
import ClearIcon from '@material-ui/icons/Clear';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import { Button, makeStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
    typography: {
        subtitle1: {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '42px',
            letterSpacing: '-0.02em',
            color: '#4F4F4F',
        },
    },
});

const colorTheme = createTheme({
    palette: {
        primary: { main: green[600] },
        secondary: { main: red[600] },
    },
});

const colorTheme2 = createTheme({
    pallete: {
        third: { main: '#808080' },
    },
});

const useStyles = makeStyles((theme) => ({
    table: {
        width: '35rem',
        margin: '0 auto',
        transform: 'scale(0.85)',
        border: '0px solid black',
        borderRadius: '8px',
        marginTop: '-2rem',
        marginBottom: '-2rem',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 920px)': {
            width: '100%',
        },
        '@media (max-width: 522px)': {
            transform: 'scale(0.8)',
            textAlign: 'center',
        },
    },
    titleSize0: {
        fontSize: '1.2rem',
        padding: '1rem auto',
        textAlign: 'center',
        fontWeight: 'bold',
        border: '1px solid #e0e0e0',
        backgroundColor: '#9e9e9e',
    },
    titleSize: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1.2rem',
        padding: '1rem 0rem 1rem 0rem ',
        fontWeight: 'bold',
        border: '1px solid #e0e0e0',
    },

    letterSize: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '21.5px',
        paddingTop: '30px',
        paddingLeft: '45px',
        paddingBottom: '30px',
        letterSpacing: '0.8px',
        border: '1px solid #e0e0e0',
        '@media (max-width: 522px)': {
            display: 'none',
        },
    },

    icon: {
        transform: 'translateY(-3px) scale(1.8)',
    },

    button: {
        fontFamily: 'Roboto, sans-serif',
        width: '18rem',
        fontWeight: 500,
        borderRadius: '15px',
        background: '#3FBC5E',
        height: '3rem',
        padding: '15px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        fontSize: '18px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },
    buttonNext: {
        fontFamily: 'Roboto, sans-serif',
        width: '10rem',
        transform: 'translateX(3.2REM)',
        fontWeight: 400,
        borderRadius: '10px',
        height: '3rem',
        padding: '15px',
        color: '#000',
        backgroundColor: 'white',
        fontSize: '16px',
        textTransform: 'inherit',
    },
    buttonPrev: {
        fontFamily: 'Roboto, sans-serif',
        width: '10rem',
        fontWeight: 400,
        borderRadius: '10px',
        transform: 'translateX(-3.2REM)',
        height: '3rem',
        padding: '15px',
        color: '#000',
        fontSize: '16px',
        textTransform: 'inherit',
    },

    formControl: {
        fontFamily: 'Roboto, sans-serif',
        width: '10rem',
        margin: '0 auto',
        padding: 0,
        '@media (max-width: 970px)': {
            width: '10rem',
        },
        '@media (max-width: 759px)': {
            textAlign: 'center',
            justifyContent: 'center',
            margin: '2vh auto',
        },
    },

    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: '45px',
        lineHeight: '72px',
        letterSpacing: '-0.02em',
        color: '#4F4F4F',
        '@media (max-width: 759px)': {
            marginBottom: '0.5rem',
            paddingLeft: 0,
        },
    },
    typographyEvaluated: {
        fontFamily: 'Roboto, sans-serif',
        fontStyle: 'normal',
        width: '20%',
        padding: '1rem',
        textAlign: 'center',
        margin: '5rem auto',
        marginBottom: '0px',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '30px',
        letterSpacing: '-0.5%',
        color: '#4F4F4F',
        '@media (max-width: 840px)': {
            padding: '0.5rem',
            width: '25%',
        },
        '@media (max-width: 580px)': {
            padding: '0.5rem',
            width: '35%',
        },
        '@media (max-width: 420px)': {
            width: '50%',
        },
    },

    typographyRow1: {
        '@media (max-width: 759px)': {
            marginBottom: '0.5rem',
            paddingLeft: 0,
        },
    },

    insideFormControl: {
        width: '10rem',
        margin: '30px 10px',
    },

    paperModal: {
        marginTop: '5%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 600,
        maxHeight: 650,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        padding: '2rem 0',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },

    buttonInsideModal: {
        fontFamily: 'Roboto, sans-serif',
        width: '16rem',
        fontWeight: 'bolder',
        borderRadius: '15px',
        height: '3.5rem',
        padding: '20px',
        transform: 'translateY(20px)',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        color: '#000',
        fontSize: '16px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },

    addAllSector: {
        fontFamily: 'Roboto, sans-serif',
        width: '100%',
        textTransform: 'inherit',
        borderRadius: '15px',
    },

    buttonModal: {
        fontFamily: 'Roboto, sans-serif',
        width: '10rem',
        margin: '0 auto',
        fontWeight: 'bolder',
        borderRadius: '15px',
        height: '3.5rem',
        color: '#fff !important',
        padding: '12px',
        transform: 'translateY(-30px)',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },

    buttonModalRemove: {
        fontFamily: 'Roboto, sans-serif',
        width: '10rem',
        margin: '0 auto',
        fontWeight: 'bolder',
        borderRadius: '15px',
        height: '3.5rem',
        color: '#fff !important',
        padding: '12px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },

    typographyNewColab: {
        fontFamily: 'Roboto, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '50px',
        letterSpacing: '-0.005em',
        color: '#4F4F4F',
    },

    formControlModalFilters: {
        width: 'auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(11rem, 1fr))',
    },

    formControlModal: {
        width: '100%',
        zIndex: '3',
        maxHeight: 200,
        display: 'grid',
        justifyContent: 'center',
        height: '85%',
        overflow: 'auto',
        gridTemplateColumns: 'repeat(auto-fill, 100px)',
        '&::-webkit-scrollbar': {
            width: '2px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey',
        },
    },

    insideFormControlColab: {
        color: '#4F4F4F',
        width: '85%',
        margin: '12px',
        display: 'grid',
        padding: '16px',
        zIndex: '2',
        fontSize: '14px',
        boxShadow: 'inset 0 0 6px rgb(0 0 0 / 0%)',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontWeight: '400',
        lineHeight: '16px',
        borderRadius: '10px',
        letterSpacing: '0.03em',
        justifyContent: 'center',
        backgroundColor: '#e5e5e5a8',
    },

    titleButton: {
        color: '#fff',
        fontWeight: '400',
    },

    clearButton1: {
        top: '-10px',
        width: '10px',
        height: '10px',
        position: 'relative',
    },

    clearButton: {
        top: '0',
        right: '0',
        width: '10px',
        height: '10px',
        color: 'rgba(0,0,0,.5)',
        opacity: '0.5',
        zIndex: '999',
        position: 'absolute',
    },

    buttonRemoval: {
        visibility: 'hidden',
    },

    tableRow: {
        '@media (max-width: 522px)': {
            display: 'none',
        },
    },

    npsName: {
        display: 'none',
        '@media (max-width: 522px)': {
            display: 'block',
            paddingTop: '10px',
        },
    },
    currentPage: {
        padding: '11px',
        height: '3rem',
    },
}));

const grades = ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'];

export default function SendNps() {
    const { auth } = React.useContext(authContext);
    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);

    const [currentUser, setCurrentUser] = React.useState(null);

    const [npsAnswer, setNpsAnswer] = React.useState([]);

    //Modal Adicionar Colaborador
    const [open, setOpen] = React.useState(false);
    //Modal remover Colaborador
    const [openRemove, setOpenRemove] = React.useState(false);

    // Fechado, Preenchido, Não preenchido
    const [npsStatus, setNpsStatus] = React.useState(null);
    const [npsStartDate, setNpsStartDate] = React.useState(null);
    const [npsEndDate, setNpsEndDate] = React.useState(null);
    const [npsId, setNpsId] = React.useState(null);

    // estado do modal de adicionar colaborador
    const [department, setDepartment] = React.useState('');
    const [departments, setDepartments] = React.useState([]);
    const [user, setUser] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [newCollaborators, setNewCollaborators] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState([]);
    const [initialUsers, setInitialUsers] = React.useState([]);

    const reducer = (state, action) => {
        if (action.type === 'refreshPage') {
            Object.keys(state).forEach((property) => {
                state[property] = npsAnswer[currentPage - 1]?.[property];
            });
        }
        return state;
    };

    const [state, dispatch] = React.useReducer(reducer, {
        feedback: null,
        proactivity: null,
        organization: null,
        relationship: null,
        accessibility: null,
    });

    React.useEffect(() => {
        const callApiFindNpsStatus = async () => {
            const response = await findNpsStatus(
                auth,
                auth.data.user.userId,
                auth.data.user.companyId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setNpsId(payload[0].npsId);

            setNpsStatus(payload[0].status);
            setNpsStartDate(payload[0].startDate);
            setNpsEndDate(payload[0].endDate);
        };

        try {
            callApiFindNpsStatus();
        } catch (error) {
            console.log(error);
        }
    }, [auth]);

    React.useEffect(() => {
        const callApiFindAllOlderUserAlignments = async () => {
            const response = await findAllOlderUserAlignments(
                auth,
                auth.data.user.companyId
            );
            let { message, payload } = await response.json();
            payload.push(auth.data.user);

            if (response.status !== 200) throw Error(message);

            setTotalPages(payload.length);

            setAllUsers(payload);

            setInitialUsers(payload);

            const initialNpsAnswer = payload.map((curr) => {
                return {
                    ratedUserId: curr.userId,
                    feedback: null,
                    proactivity: null,
                    organization: null,
                    relationship: null,
                    accessibility: null,
                };
            });

            setNpsAnswer(initialNpsAnswer);
        };

        try {
            callApiFindAllOlderUserAlignments();
        } catch (error) {
            console.log(error);
        }
    }, [auth]);

    React.useEffect(() => {
        const callApiFindAllAlignmentsPaged = async () => {
            const response = await findAllAlignmentsPaged(
                auth,
                auth.data.user.userId,
                auth.data.user.companyId,
                currentPage
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length === 0) {
                setCurrentUser(allUsers[currentPage - 1]);
            } else setCurrentUser(payload[0]);
        };

        try {
            callApiFindAllAlignmentsPaged();
        } catch (error) {
            console.log(error);
        }
    }, [allUsers, auth, currentPage, totalPages]);

    const classes = useStyles();

    React.useEffect(() => {
        dispatch({ type: 'refreshPage' });
    }, [currentPage, npsAnswer]);

    React.useEffect(() => {
        const callApiFindAllDepartments = async () => {
            const response = await getAllDepartments(
                auth,
                auth.data.user.companyId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setDepartments(payload);
        };

        try {
            callApiFindAllDepartments();
        } catch (error) {
            console.log(error);
        }
    }, [auth]);

    React.useEffect(() => {
        const callApiFindAllOlderUsersInDepartment = async () => {
            const response = await getAllOlderUsersInDepartment(
                auth,
                department.departmentId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setUsers(payload);
        };

        const callApiFindAllOlderUsers = async () => {
            const response = await getAllOlderUsersInCompany(
                auth,
                auth.data.user.companyId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setUsers(payload);
        };

        try {
            if (department.departmentId) callApiFindAllOlderUsersInDepartment();
            else callApiFindAllOlderUsers();
        } catch (error) {
            console.log(error);
        }
    }, [auth, department.departmentId]);

    const defaultPropsFeedback = {
        options: grades,
        value: state?.feedback,
        getOptionLabel: (option) => {
            return String(option) || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            let newArr = [...npsAnswer];
            newArr[currentPage - 1].feedback = Number(newValue);
            setNpsAnswer(newArr);
            dispatch({ type: 'refreshPage' });
        },
    };

    const defaultPropsProactivity = {
        options: grades,
        value: state?.proactivity,
        getOptionLabel: (option) => {
            return String(option) || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            let newArr = [...npsAnswer];
            newArr[currentPage - 1].proactivity = Number(newValue);
            setNpsAnswer(newArr);
            dispatch({ type: 'refreshPage' });
        },
    };

    const defaultPropsOrganization = {
        options: grades,
        value: state?.organization,
        getOptionLabel: (option) => {
            return String(option) || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            let newArr = [...npsAnswer];
            newArr[currentPage - 1].organization = Number(newValue);
            setNpsAnswer(newArr);
            dispatch({ type: 'refreshPage' });
        },
    };

    const defaultPropsRelationship = {
        options: grades,
        value: state?.relationship,
        getOptionLabel: (option) => {
            return String(option) || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            let newArr = [...npsAnswer];
            newArr[currentPage - 1].relationship = Number(newValue);
            setNpsAnswer(newArr);
            dispatch({ type: 'refreshPage' });
        },
    };

    const defaultPropsAccessibility = {
        options: grades,
        value: state?.accessibility,
        getOptionLabel: (option) => {
            return String(option) || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            let newArr = [...npsAnswer];
            newArr[currentPage - 1].accessibility = Number(newValue);
            setNpsAnswer(newArr);
            dispatch({ type: 'refreshPage' });
        },
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenRemove = () => {
        setOpenRemove(true);
    };

    const handleCloseRemove = () => {
        setOpenRemove(false);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handleRemoveItem = (id) => {
        setNewCollaborators(
            newCollaborators.filter((user) => user.userId !== id)
        );
    };

    const checkNpsAnswers = () => {
        for (let answer of npsAnswer) {
            if (
                answer.feedback === null ||
                answer.proactivity === null ||
                answer.organization === null ||
                answer.relationship === null ||
                answer.accessibility === null
            ) {
                return true;
            }
        }
        return false;
    };

    const saveNpsAnswers = async () => {
        try {
            const response = await createNpsAnswers(
                auth,
                auth.data.user.userId,
                auth.data.user.companyId,
                npsId,
                { answers: npsAnswer }
            );

            const { message } = await response.json();

            if (response.status !== 201) throw Error(message);
            setPopupTitle('Sucesso');
            setPopupText('Nps salvo com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Nps não pôde ser salvo: ${error.message}`);
            setPopup(true);
            setSuccess(0);
        }
    };

    const defaultPropsDepartment = {
        options: [
            { companyId: null, departmentId: null, name: 'Nenhum' },
            ...departments,
        ],
        value: department,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setDepartment(newValue);
        },
    };

    const defaultPropsUser = {
        options: users,
        value: user,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        getOptionDisabled: (option) => {
            return !!allUsers.find(
                (curr) =>
                    curr.userId === option.userId ||
                    option.userId === auth.data.user.userId
            );
        },
        onChange: (event, newValue) => {
            setUser(newValue);

            if (
                !allUsers
                    .map((curr) => curr.userId)
                    .includes(newValue.userId) &&
                newValue.userId !== auth.data.user.userId
            )
                setNewCollaborators((users) => [
                    ...users.filter((curr) => curr.userId !== newValue.userId),
                    newValue,
                ]);
        },
    };

    function handleAddAllCollabs() {
        if (department && users) {
            const allowedUsers = users.filter(
                (curr) =>
                    curr.userId !== auth.data.user.userId &&
                    !initialUsers
                        .map((curr) => curr.userId)
                        .includes(curr.userId) &&
                    !newCollaborators
                        .map((curr) => curr.userId)
                        .includes(curr.userId) &&
                    !allUsers.map((curr) => curr.userId).includes(curr.userId)
            );
            setNewCollaborators((collaborators) => [
                ...collaborators,
                ...allowedUsers,
            ]);
        }
    }

    const removeColabs = (
        <div className={classes.paperModal}>
            <ContainerModal>
                <Row1Modal>
                    <div>
                        <ThemeProvider theme={theme}>
                            <Typography className={classes.typographyNewColab}>
                                Remover Colaborador{' '}
                            </Typography>
                            <Typography variant="subtitle1">
                                <strong>
                                    {' '}
                                    {`Deseja remover ${currentUser?.name}
                                do seu NPS?`}
                                </strong>
                            </Typography>
                        </ThemeProvider>
                    </div>
                </Row1Modal>
                <Row2Modal>
                    <ThemeProvider theme={colorTheme}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="button"
                            className={classes.buttonModalRemove}
                            onClick={() => {
                                const newUsers = allUsers.filter(
                                    (curr) => curr.userId !== currentUser.userId
                                );
                                setAllUsers(newUsers);
                                setNpsAnswer((answer) => {
                                    const users = newUsers.map(
                                        (curr) => curr.userId
                                    );

                                    return answer.filter((curr) => {
                                        return users.includes(curr.ratedUserId);
                                    });
                                });
                                setTotalPages((page) => page - 1);
                                setCurrentPage((page) => page - 1);

                                setUser('');
                                setDepartment('');
                                handleCloseRemove();
                            }}
                        >
                            <span className={classes.titleButton}>Sim</span>
                        </Button>
                    </ThemeProvider>

                    <ThemeProvider theme={colorTheme}>
                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            type="button"
                            className={classes.buttonModalRemove}
                            onClick={handleCloseRemove}
                        >
                            <span className={classes.titleButton}>
                                Cancelar
                            </span>
                        </Button>
                    </ThemeProvider>
                </Row2Modal>
            </ContainerModal>
        </div>
    );

    const newColabs = (
        <div className={classes.paperModal}>
            <ContainerModal>
                <Row1Modal>
                    <ThemeProvider theme={theme}>
                        <Typography className={classes.typographyNewColab}>
                            Adicionar colaborador{' '}
                        </Typography>
                        <Typography variant="subtitle1">
                            Adicione ao seu NPS colaboradores de setor externo.
                        </Typography>
                    </ThemeProvider>
                </Row1Modal>
                <Row2Modal>
                    <div className={classes.formControlModalFilters}>
                        <Autocomplete
                            {...defaultPropsDepartment}
                            id="input-departments"
                            autoSelect
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.insideFormControl}
                                    label="Setor"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Autocomplete
                            {...defaultPropsUser}
                            id="input-users"
                            autoSelect
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.insideFormControl}
                                    label="Colaborador"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </Row2Modal>
                <Row3Modal>
                    <div className={classes.formControlModal}>
                        {newCollaborators.map((user) => (
                            <div
                                className={classes.insideFormControlColab}
                                key={user.userId}
                            >
                                <Button
                                    className={classes.clearButton1}
                                    onClick={() =>
                                        handleRemoveItem(user.userId)
                                    }
                                >
                                    <ClearIcon
                                        className={classes.clearButton}
                                    />
                                </Button>{' '}
                                {user.name}{' '}
                            </div>
                        ))}
                    </div>
                </Row3Modal>
                <Row4Modal>
                    {' '}
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="button"
                        disabled={!department.departmentId}
                        className={classes.addAllSector}
                        onClick={() => handleAddAllCollabs()}
                    >
                        Adicionar setor
                    </Button>
                    <Button
                        color="primary"
                        className={classes.buttonInsideModal}
                        disabled={newCollaborators.length === 0 ? true : false}
                        onClick={() => {
                            setAllUsers((users) => [
                                ...users,
                                ...newCollaborators.filter(
                                    (curr) =>
                                        !allUsers
                                            .map((user) => user.userId)
                                            .includes(curr.userId)
                                ),
                            ]);
                            setCurrentPage(totalPages + 1);
                            setTotalPages(
                                (totalPages) =>
                                    totalPages + newCollaborators.length
                            );
                            const newNpsAnswer = newCollaborators.map(
                                (curr) => {
                                    return {
                                        ratedUserId: curr.userId,
                                        feedback: null,
                                        proactivity: null,
                                        organization: null,
                                        relationship: null,
                                        accessibility: null,
                                    };
                                }
                            );
                            setNpsAnswer([...npsAnswer, ...newNpsAnswer]);
                            setNewCollaborators([]);
                        }}
                    >
                        Adicionar Colaboradores
                    </Button>
                </Row4Modal>
            </ContainerModal>
        </div>
    );

    const startDate = new Date(npsStartDate?.split('/').reverse().join('/'));

    const endDate = new Date(npsEndDate?.split('/').reverse().join('/'));

    return npsStatus === 'Fechado' ? (
        <Container>
            <ContainerRow>
                {' '}
                <Row2Closed>
                    {' '}
                    <Typography className={classes.typography} variant="h6">
                        {`Olá, ${auth.data.user.name.split(' ')[0]}! `}
                        <Unavailable>
                            O <strong>NPS</strong> não está disponível no
                            momento!
                        </Unavailable>
                    </Typography>{' '}
                </Row2Closed>{' '}
            </ContainerRow>
        </Container>
    ) : (
        <>
            <Container>
                <ContainerRow>
                    <Row1>
                        {' '}
                        <FirstColumn>
                            <Typography
                                className={classes.typography}
                                variant="h6"
                            >
                                {`Olá, ${auth.data.user.name.split(' ')[0]}! `}
                                <Availability>
                                    O <strong>NPS</strong> está disponível de{' '}
                                    <strong>
                                        {new Date(startDate).toLocaleDateString('pt-br')}
                                    </strong>{' '}
                                    a{' '}
                                    <strong>
                                        {new Date(endDate).toLocaleDateString('pt-br')}
                                    </strong>
                                    !
                                </Availability>
                            </Typography>{' '}
                        </FirstColumn>
                        <SecondColumn>
                            <Observation>
                                {' '}
                                Avaliando pesquisas realizadas, notamos a
                                importância de mensurarmos os aspectos onde
                                estamos muito bem e onde precisamos melhorar.
                                Assim, cada um terá a oportunidade de avaliar
                                seu colega. <br />
                                <strong>
                                    Cada avaliação considera notas de 0 à 10.
                                </strong>
                            </Observation>
                        </SecondColumn>
                    </Row1>{' '}
                    {npsStatus === 'Preenchido' ? (
                        <Row2>
                            {' '}
                            <Filled>
                                <strong>O NPS já foi respondido!</strong>
                            </Filled>{' '}
                        </Row2>
                    ) : (
                        <div>
                            <Row2>
                                <Typography
                                    className={classes.typographyEvaluated}
                                    variant="h6"
                                >
                                    {currentUser?.name}
                                </Typography>
                                <TableContainer
                                    className={classes.table}
                                    component={Paper}
                                >
                                    <Table
                                        sx={{ minWidth: 250 }}
                                        size="small"
                                        aria-label="a dense table"
                                    >
                                        <TableHead>
                                            <TableRow
                                                className={classes.tableRow}
                                            >
                                                <TableCell
                                                    className={
                                                        classes.titleSize0
                                                    }
                                                    style={{
                                                        paddingLeft: '30px',
                                                    }}
                                                    align="left"
                                                >
                                                    Aspecto
                                                </TableCell>

                                                <TableCell
                                                    className={
                                                        classes.titleSize
                                                    }
                                                    align="center"
                                                >
                                                    Nota
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                {' '}
                                                <TableCell
                                                    className={
                                                        classes.letterSize
                                                    }
                                                    align="left"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Feedback
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.importValue
                                                    }
                                                    align="center"
                                                >
                                                    <Filled
                                                        className={
                                                            classes.npsName
                                                        }
                                                    >
                                                        Feedback
                                                    </Filled>

                                                    <Autocomplete
                                                        {...defaultPropsFeedback}
                                                        id="input-feedback"
                                                        autoSelect
                                                        disableClearable
                                                        className={
                                                            classes.formControl
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                {' '}
                                                <TableCell
                                                    className={
                                                        classes.letterSize
                                                    }
                                                    align="left"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Proatividade
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.importValue
                                                    }
                                                    align="center"
                                                >
                                                    <Filled
                                                        className={
                                                            classes.npsName
                                                        }
                                                    >
                                                        Proatividade
                                                    </Filled>
                                                    <Autocomplete
                                                        {...defaultPropsProactivity}
                                                        id="input-proactivity"
                                                        autoSelect
                                                        disableClearable
                                                        className={
                                                            classes.formControl
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                {' '}
                                                <TableCell
                                                    className={
                                                        classes.letterSize
                                                    }
                                                    align="left"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Organização
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.importValue
                                                    }
                                                    align="center"
                                                >
                                                    <Filled
                                                        className={
                                                            classes.npsName
                                                        }
                                                    >
                                                        Organização
                                                    </Filled>
                                                    <Autocomplete
                                                        {...defaultPropsOrganization}
                                                        id="input-organization"
                                                        autoSelect
                                                        disableClearable
                                                        className={
                                                            classes.formControl
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                {' '}
                                                <TableCell
                                                    className={
                                                        classes.letterSize
                                                    }
                                                    align="left"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Relacionamento
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.importValue
                                                    }
                                                    align="center"
                                                >
                                                    <Filled
                                                        className={
                                                            classes.npsName
                                                        }
                                                    >
                                                        Relacionamento
                                                    </Filled>
                                                    <Autocomplete
                                                        {...defaultPropsRelationship}
                                                        id="input-relationship"
                                                        autoSelect
                                                        disableClearable
                                                        className={
                                                            classes.formControl
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    className={
                                                        classes.letterSize
                                                    }
                                                    align="left"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Acessibilidade
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.importValue
                                                    }
                                                    align="center"
                                                >
                                                    <Filled
                                                        className={
                                                            classes.npsName
                                                        }
                                                    >
                                                        Acessibilidade
                                                    </Filled>
                                                    <Autocomplete
                                                        {...defaultPropsAccessibility}
                                                        id="input-accessibility"
                                                        autoSelect
                                                        blurOnSelect="mouse"
                                                        disableClearable
                                                        className={
                                                            classes.formControl
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                {/* <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                >
                                    {newColabs}
                                </Modal> */}
                            </Row2>
                            <Row3>
                                {/* <PositiveButton>
                                    <ThemeProvider theme={colorTheme}>
                                        {currentPage >= initialUsers.length ? (
                                            <Button
                                                variant="contained"
                                                size="large"
                                                color="primary"
                                                type="button"
                                                className={classes.buttonModal}
                                                onClick={handleOpen}
                                            >
                                                <span
                                                    className={
                                                        classes.titleButton
                                                    }
                                                >
                                                    + Colaborador
                                                </span>
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                size="large"
                                                color="primary"
                                                type="button"
                                                className={
                                                    classes.buttonRemoval
                                                }
                                            >
                                                <span> </span>
                                            </Button>
                                        )}
                                    </ThemeProvider>
                                </PositiveButton> */}
                                {/* <NegativeButton>
                                    <ThemeProvider theme={colorTheme}>
                                        {initialUsers
                                            .map((curr) => {
                                                return curr?.userId;
                                            })
                                            .includes(currentUser?.userId) ? (
                                            <Button
                                                variant="contained"
                                                size="large"
                                                color="secondary"
                                                type="button"
                                                className={
                                                    classes.buttonRemoval
                                                }
                                                onClick={handleOpenRemove}
                                            >
                                                <span> </span>
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                size="large"
                                                color="secondary"
                                                type="button"
                                                className={classes.buttonModal}
                                                onClick={handleOpenRemove}
                                            >
                                                <span
                                                    className={
                                                        classes.titleButton
                                                    }
                                                >
                                                    Remover Colaborador
                                                </span>
                                            </Button>
                                        )}
                                    </ThemeProvider>
                                </NegativeButton> */}
                                {/* <Modal
                                    open={openRemove}
                                    onClose={handleCloseRemove}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    disabled={initialUsers
                                        .map((curr) => curr?.userId)
                                        .includes(currentUser?.userId)}
                                >
                                    {removeColabs}
                                </Modal> */}
                                <SendButton>
                                    <ThemeProvider theme={colorTheme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            // endIcon={startIcon}
                                            disabled={checkNpsAnswers()}
                                            onClick={() => {
                                                saveNpsAnswers();
                                            }}
                                        >
                                            Enviar
                                        </Button>
                                    </ThemeProvider>
                                </SendButton>
                                <PaginationGrid>
                                    <Pagination>
                                        <ThemeProvider theme={colorTheme2}>
                                            <Button
                                                variant="contained"
                                                color="third"
                                                disabled={currentPage === 1}
                                                onClick={() =>
                                                    handlePageClick(
                                                        currentPage - 1
                                                    )
                                                }
                                                className={classes.buttonPrev}
                                            >
                                                Anterior
                                            </Button>
                                        </ThemeProvider>
                                        <span className={classes.currentPage}>
                                            {`${currentPage}/${totalPages}`}
                                        </span>
                                        <ThemeProvider theme={colorTheme2}>
                                            <Button
                                                variant="contained"
                                                color="third"
                                                className={classes.buttonNext}
                                                disabled={
                                                    currentPage === totalPages
                                                }
                                                onClick={() =>
                                                    handlePageClick(
                                                        currentPage + 1
                                                    )
                                                }
                                            >
                                                Próximo
                                            </Button>
                                        </ThemeProvider>
                                    </Pagination>
                                </PaginationGrid>
                            </Row3>
                        </div>
                    )}
                </ContainerRow>
            </Container>
            {popup && (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                />
            )}
        </>
    );
}
